import "./index.css";
import "./responsive.css";
import SectionHeader from "../../../components/shared/section-header";
import Testimonial from "../../../images/img4.jpg";
import Button from "../../../components/shared/button"
import { Link } from 'react-router-dom';

const ClientTestimonial = () => {
	return (
		<section className="client-testimonial">
			<img src={Testimonial} alt="" className="testimonial-image" />
			<div className="testimonial-container">
				<SectionHeader name={"Portfolio"} />
				<div className="client-testimony">
					<h1 className="testimony-title">Explore our Portfolio</h1>
					<p className="subtitle">
						From revamping renowned restaurant spaces to designing cutting-edge
						hotel kitchens, our projects redefine the art of cooking. Explore
						our portfolio and witness how we transform kitchen spaces into
						vibrant hubs of culinary creativity, tailored to elevate the unique
						vision of each client.
					</p>
                    <Button classname={"learn-more"}><Link to={"/portfolio"}>Learn More</Link></Button>
				</div>
			</div>
		</section>
	);
};

export default ClientTestimonial;
