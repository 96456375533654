import { Link } from "react-router-dom";
import "./index.css";
import "./responsive.css"
import {
	FaFacebook,
	FaXTwitter,
	FaYoutube,
    FaSquareBehance,
} from "react-icons/fa6";
import Button from "../../shared/button";

const Footer = () => {
	return (
		<footer className="footer">
			<Link className="footer-logo">
				<h1>EddyMoore</h1>
				<p>Tech</p>
			</Link>
			<section className="footer-content">
				<div className="other-pages">
					<h1>Other Pages</h1>
					<ul>
						<li>
							<Link to={"/"}>Home</Link>
						</li>
						<li>
							<Link to={"/about"}>About Us</Link>
						</li>
						<li>
							<Link to={"/services"}>Services</Link>
						</li>
						<li>
							<Link to={"/contact"}>Contact</Link>
						</li>
						<li>
							<Link to={"/portfolio"}>Portfolio</Link>
						</li>
					</ul>
				</div>
				<div className="footer-services">
					<h1>Our Services</h1>
					<ul>
						<li>
							<Link to={"/sales"}>Sales</Link>
						</li>
						<li>
							<Link to={"/supplies"}>Supplies</Link>
						</li>
						<li>
							<Link to={"/install"}>Installation</Link>
						</li>
						<li>
							<Link to={"/repair"}>Repair & Servicing</Link>
						</li>
					</ul>
				</div>
				<div className="newsletter-form">
					<h1>Connect with us</h1>
					<div className="newsletter">
						<Button><FaFacebook size={25} fill="#000" /></Button>
						<Button><FaXTwitter size={25} fill="#000" /></Button>
						<Button><FaSquareBehance size={25} fill="#000" /></Button>
						<Button><FaYoutube size={25} fill="#000" /></Button>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default Footer;
