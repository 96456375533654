import "./index.css";
import "./responsive.css";
import Button from "../../../components/shared/button";
import AboutFour from "../../../images/image2.jpg";
import SectionHeader from "../../../components/shared/section-header";
import { Link } from "react-router-dom";

const CompanyServices = () => {
	return (
		<section className="company-services-container">
			<div className="company-services-image">
				<img src={AboutFour} alt="our services" />
			</div>
			<div className="company-services-info">
				<SectionHeader name={"Our Services"} />
				<h1>Welcome to EddyMoore Tech</h1>
				<p className="subtitle">
					EddyMooreTech oofers a comprehensive range of industrial equipment
					services tailored toward meeting the diverse needs of corporate
					establishments, including those in the hospitaility sector.
				</p>
				<p className="subtitle">
					The company has a team of skilled technicians who ensure seamless and
					efficient installation processe, with caution for safety. We also
					provide regular maintenance and servicing of industrial equipment to
					ensure optimal performance and longevity, thereby helping businesses
					to minimize downtime and maximoze productivity.
				</p>
				<p className="more-info">
					Additionally, the company offers a curated selection of high quality
					industrial equipment for sale.
				</p>
				<Button classname={"service-btn"}>
					<Link to={"/services"}>Our Services</Link>
				</Button>
			</div>
		</section>
	);
};

export default CompanyServices;
