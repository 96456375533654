import "./index.css";
import "./responsive.css";
import { FaTimes } from "react-icons/fa";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdMenu } from "react-icons/io";
import Button from "../../shared/button";
import { FiPhone } from "react-icons/fi";
import { IoMailOpenOutline } from "react-icons/io5";
import { GrMapLocation } from "react-icons/gr";

const NavBar = () => {
	const [open, setOpen] = useState(false);
	const [scrolled, setScrolled] = useState(false);

	const openMenu = () => setOpen(true);
	const closeMenu = () => setOpen(false);
	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 200) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
	});
	let navbarClasses = ["navbar"];
	if (scrolled) {
		navbarClasses.push("scrolled");
	}
	return (
		<nav className={navbarClasses.join(" ")}>
			<section className="navbar-content">
				<Link to={"/"} className="logo">
					<h1>EddyMoore</h1>
					<h2>Tech</h2>
				</Link>
				<ul className="nav-items">
					<li className="nav-itmem">
						<Link to={"/"}>Home</Link>
					</li>
					<li className="nav-itmem">
						<Link to={"/about"}>About us</Link>
					</li>
					<li className="nav-itmem">
						<Link to={"/services"}>Services</Link>
					</li>
					<li className="nav-itmem">
						<Link to={"/contact"}>Contact</Link>
					</li>
					<li className="nav-itmem">
						<Link to={"/portfolio"}>Portfolio</Link>
					</li>
				</ul>
			</section>
			<section className="contact-details">
				<Link to={"/"}>
					<h1>Phone number</h1>
					<h2>08037175061</h2>
				</Link>
				<Link to={"mailto:eddymooretech@gmail.com"}>
					<h1>Free consultancy</h1>
					<h2>eddymooretech@gmail.com</h2>
				</Link>
			</section>
			<Button classname={"menu_btn"} clickHandler={openMenu}>
				<IoMdMenu size={30} />
			</Button>
			<div
            onClick={closeMenu}
				className={open ? "mobile-menu-backdrop show" : "mobile-menu-backdrop"}
			></div>
			<section className={open ? "mobile-menu" : "mobile-menu hide"}>
				<div className="mobile-menu-header">
					<div className="mobile-menu-logo-text">
						<h1>EddyMoore</h1>
						<p>Tech</p>
					</div>
					<Button classname="close-menu" clickHandler={closeMenu}>
						<FaTimes size={25} />
					</Button>
				</div>
				<ul className="mobile-menu-items">
					<li className="mobile-menu-item">
						<Link to={"/"}>Home</Link>
					</li>
					<li className="mobile-menu-item">
						<Link to={"/about"}>About us</Link>
					</li>
					<li className="mobile-menu-item">
						<Link to={"/services"}>Services</Link>
					</li>
					<li className="mobile-menu-item">
						<Link to={"/contact"}>Contact</Link>
					</li>
					<li className="mobile-menu-item">
						<Link to={"/portfolio"}>Portfolio</Link>
					</li>
				</ul>
				<div className="mobile-menu-contact-info">
					<h1>Contact info</h1>
					<div className="menu-contact-info">
						<div className="menu-contact-icon">
							<GrMapLocation size={25} />
						</div>
						<p>PLOT 3743A New Site Sauka Kahuta, FHA, Lugbe</p>
					</div>
					<div className="menu-contact-info">
						<div className="menu-contact-icon">
							<IoMailOpenOutline size={25} />
						</div>
						<p>eddymooretech@gmail.com</p>
					</div>
					<div className="menu-contact-info">
						<div className="menu-contact-icon">
							<FiPhone size={25} />
						</div>
						<p>08037175061</p>
					</div>
				</div>
			</section>
		</nav>
	);
};

export default NavBar;
