import "./tabs.css"
import "./responsive.css"
import { Children } from "react"
import { useEddyMoreContext } from "../../../context"

const CompanyInfoTabs =({ children })=>{
    const { currentTab } = useEddyMoreContext()
    return(
        <section className="company-info-tabs">{
            Children.map(children, (child, indx) => indx === currentTab ? child : null)
        }</section>
    )
}

export default CompanyInfoTabs