import "./index.css";
import "./responsive.css"
import ServiceCard from "../../../components/shared/service";
import { useEddyMoreContext } from "../../../context";

const MyServices = () => {
	const { data } = useEddyMoreContext();

	return (
		<section className="my-services">
			{data.map(({ title, icon, desc }, indx) => (
				<ServiceCard key={title} title={title} desc={desc} icon={icon} />
			))}
		</section>
	);
};

export default MyServices;
