import "./index.css";
import ImageOne from "../../../images/img.jpg";
import ImageTwo from "../../../images/image2.jpg";
import ImageThree from "../../../images/image3.jpg";
import ImageFour from "../../../images/img4.jpg";
import ImageFive from "../../../images/img5.jpg";
import ImageSix from "../../../images/img6.jpg";
import ImageSeven from "../../../images/img7.jpg";
import ImageEight from "../../../images/image12.jpg";
import ImageNine from "../../../images/image3.jpg";
import ImageTen from "../../../images/image18.jpg";
import ImageEleven from "../../../images/image4.jpg";
import ImageTwelve from "../../../images/image5.jpg";
import ImageThirteen from "../../../images/image15.jpg";

const images = [
	{
		key: "one",
		image: ImageOne,
	},
	{
		key: "two",
		image: ImageTwo,
	},
	{
		key: "three",
		image: ImageThree,
	},
	{
		key: "four",
		image: ImageFour,
	},
	{
		key: "five",
		image: ImageFive,
	},
	{
		key: "six",
		image: ImageSix,
	},
	{
		key: "seven",
		image: ImageSeven,
	},
	{
		key: "eight",
		image: ImageEight,
	},
	{
		key: "nine",
		image: ImageNine,
	},
	{
		key: "ten",
		image: ImageTen,
	},
	{
		key: "eleven",
		image: ImageEleven,
	},
	{
		key: "twelve",
		image: ImageTwelve,
	},
	{
		key: "thirteen",
		image: ImageThirteen,
	},
];

const GalleryGrid = () => {
	return (
		<section className="gallery-grid-container">
			<section className="gallery-grid">
				{images.map(({ key, image }, indx) => (
					<div className="grid-item">
						{" "}
						<img key={key} src={image} alt={key} />
					</div>
				))}
			</section>
		</section>
	);
};

export default GalleryGrid