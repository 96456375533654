import "./index.css";
import "./responsive.css";
import Button from "../../components/shared/button";
import AboutOne from "../../images/img2.jpg";
import AboutTwo from "../../images/img3.jpg";
import AboutThree from "../../images/img4.jpg";
import AboutFour from "../../images/image2.jpg";
import SectionHeader from "../../components/shared/section-header";

const AboutCompany = () => {
	return (
		<section className="about-company">
			<div className="about-grid">
				<div className="grid-image-left">
					<div className="grid-image">
						<img src={AboutOne} alt="about" />
					</div>
					<div className="grid-image">
						<img src={AboutTwo} alt="about" />
					</div>
				</div>
				<div className="grid-image-right">
					<div className="grid-image">
						<img src={AboutThree} alt="about" />
					</div>
					<div className="grid-image">
						<div className="grid-text">
							<h1>
								15<span>+</span>
							</h1>
							<p>years of experience</p>
						</div>
						<img src={AboutFour} alt="about" />
					</div>
				</div>
			</div>
			<div className="about-info">
				<SectionHeader name={"About Us"} />
				<h1>We can take your business to the next level</h1>
				<p className="subtitle">
					EddyMooreTech Ltd. is a leading technical service firm in Nigeria Specializing in the installation, servicing, and sales of industrial equipment, including industrial laundry, Dry cleaning, Finishing equipment, Kitchen equipment, Bakery, and catering machines. 
				</p>
				<p className="founder-name">
					Engr. Edmond Orji Chukwuma - <span>Founder</span>
				</p>
				<p className="about-desc">
				Headquartered in Abuja, Nigeria, the company was established in 2009 and has a strong track record of passion for excellence, durablilty, and reliability.
				</p>
				<Button classname={"learn-more"}>Learn more</Button>
			</div>
		</section>
	);
};

export default AboutCompany;
