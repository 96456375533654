import "./contact.css";
import "./responsive.css";

const ContactUs = () => {
	return (
		<section className="contact-us">
			<div className="contact-container">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31529.156225204548!2d7.336047503277635!3d8.958827355324027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e724ce523c71b%3A0xd9e4fb48232178e7!2sLugbe%20900107%2C%20Federal%20Capital%20Territory!5e0!3m2!1sen!2sng!4v1710948977207!5m2!1sen!2sng"
					width="600"
					height="450"
					style={{ border: "0" }}
					allowfullscreen=""
					loading="lazy"
					referrerpolicy="no-referrer-when-downgrade"
					title="map"
				></iframe>
			</div>
		</section>
	);
};

export default ContactUs;
