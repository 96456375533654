import Layout from "../../components/layout";
import PageHero from "../../components/shared/pageHero";
import HeroContent from "../../components/shared/hero";
import GalleryGrid from "./grid";

const Portfolio = () => {
	return (
		<Layout>
			<HeroContent>
				<PageHero classname={"portfolio-hero"} pageTitle={"Gallery"} />
			</HeroContent>
            <GalleryGrid />
		</Layout>
	);
};

export default Portfolio;
